/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Twitter from "@material-ui/icons/Twitter";
import LinkedIn from "@material-ui/icons/LinkedIn";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import Button from "components/CustomButtons/Button.js";


const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
      <footer className={footerClasses}>
        <div className={classes.container}>
          <div className={classes.center}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>

                <b>Copyright © {1900 + new Date().getYear()} | E-WAVE-LABS</b>

              </ListItem>
{/*              <ListItem className={classes.inlineBlock}>
                <a
                    href="#"
                    className={classes.block}
                    target="_blank"
                >
                  A propos
                </a>
              </ListItem>

              <ListItem className={classes.inlineBlock}>
                <a
                    href="#"
                    className={classes.block}
                    target="_blank"
                >
                  Mentions légales
                </a>
              </ListItem>

              <ListItem className={classes.inlineBlock}>
                <a
                    href="#"
                    className={classes.block}
                    target="_blank"
                >
                  Contactez nous
                </a>
              </ListItem>*/}

              <ListItem className={classes.inlineBlock}>
                <Button
                    color="transparent"
                    href="https://www.linkedin.com/company/e-waves-labs/"
                    target="_blank"
                    className={classes.navLink}
                >
                  <LinkedIn className={classes.icons} />
                </Button>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Button
                    color="transparent"
                    href="https://twitter.com/?lang=fr"
                    target="_blank"
                    className={classes.navLink}
                >
                  <Twitter className={classes.icons} />
                </Button>
              </ListItem>
            </List>
          </div>
        </div>
      </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
