import React, { useState, useReducer } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value,
  };
};
export default function WorkSection() {
  const classes = useStyles();
  const [setFormData] = useReducer(formReducer, {});

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);

    setTimeout(() => {
      setSubmitting(false);
    }, 3000);
  };
  const handleChange = (event) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
    console.log("new event");
    console.log(event.target.name + "=" + event.target.value);
  };
  return (
    <div id="contact" className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Contact Us</h2>
          <h4 className={classes.legal_information}>
            We{"'"}d love to learn more about your business needs, please tell
            us how we can help you to deliver the results you want.
          </h4>
          <h4 className={classes.legal_information}>
            By sending this form, I agree to the use of my data for the
            processing of my request in accordance with the GDPR (General Data
            Protection Regulation)
          </h4>

          {submitting && (
            <h4 className={classes.legal_information}>
              Sending in progress ...
            </h4>
          )}

          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Name *"
                  id="name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: handleChange,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Email Address *"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: handleChange,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Phone number *"
                  id="phone"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: handleChange,
                  }}
                />
              </GridItem>
              <CustomInput
                labelText="Your message *"
                id="message"
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea,
                }}
                inputProps={{
                  multiline: true,
                  rows: 5,
                  onChange: handleChange,
                }}
              />
              <GridItem xs={12} sm={12} md={12}>
                <h7 className={classes.form_required_text}>
                  Fields marked * are required
                </h7>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Button color="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
