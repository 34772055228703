import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import HttpTwoTone from "@material-ui/icons/HttpTwoTone";
import AllInclusiveTwoTone from "@material-ui/icons/AllInclusiveTwoTone";
import CloudDoneTwoTone from "@material-ui/icons/CloudDoneTwoTone";
import MobileFriendlyTwoTone from "@material-ui/icons/MobileFriendlyTwoTone";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div id="work" className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Our areas of expertise</h2>
          <h5 className={classes.description}>
            Web applications development is the heart of our business. Our teams
            will make every effort to support you, advise you and guide you on
            specific themes falling within our areas of expertise.
          </h5>
          <h5 className={classes.description}>
            Our teams will make every effort to support you, advise you and
            guide you on specific themes falling within our areas of expertise.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <InfoArea
              title="WEB DEVELOPMENT"
              description="Our teams support you from the recovery of the need to the start of production, as well as the maintenance of your existing applications."
              icon={HttpTwoTone}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <InfoArea
              title="DEVOPS"
              description="Our teams are working on pipelines, continuous integration, continuous deployment, configuration management and Monitoring."
              icon={AllInclusiveTwoTone}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <InfoArea
              title="CLOUD COMPUTING"
              description="Our teams are working on many public cloud provider AWS, GCP, MS AZURE and also on promise using Openstack."
              icon={CloudDoneTwoTone}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <InfoArea
              title="MOBILE DEVELOPMENT"
              description="Our teams are working in mobile technology such as building apps for Google's Android and Apple's iOS."
              icon={MobileFriendlyTwoTone}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
